
import React from 'react';
import './Button.css';
import { Link } from "react-router-dom";

const Button = (props) => {
	return (
		<Link to={props.to} state={props.state}>
			<button onClick={props.onClick} className={props.className}>{props.loading ? "..." : props.label}</button>
		</Link>
	);
}

export default Button;
