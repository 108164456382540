import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import Button from '../Components/Button';
import {  collection, addDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import './BetaModal.css';

const BetaModal = () => {
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	// TODO: clean this up
	useEffect(() => {
		const url = window.location.href;
		const domain = url.split('/')[2];
		if (domain.includes("localhost")) return;
		window.location.href = `https://app.${domain}`;
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		// email validation
		if (!email || !email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
			alert("Please enter a valid email.");
			setLoading(false);
			return;
		}

		//store to firestore
		const doc = {
			email: email,
			message: message,
			created_at: Date.now()
		}
		addDoc(collection(db, "beta-email-list"), doc).then((docRef) => {
			setEmail("");
			setMessage("");
		}).catch((error) => {
			console.error("Error adding document: ", error);
			alert("Something went wrong. Please try again.");
		});
		setLoading(false);
	}

	return (
		// TODO: clean this up
		<div style={{display: "none"}}> 
		<Modal>
			<form onSubmit={handleSubmit}>
				<h1>Join the beta</h1>
				<p>We are in the process of collecting a base list of publishers before launching. Please enter your email and we will add you to the list.</p>
				{/* email input required and pattern validation */}
				<input type="email" placeholder="Email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
				value={email} onChange={(e) => setEmail(e.target.value)} />
				<textarea placeholder="What you're looking for with CodeBarter ..." value={message} onChange={(e) => setMessage(e.target.value)} />
				<Button label="Join" loading={loading} className="btn-primary btn-big" onClick={handleSubmit}/>
			</form>
		</Modal>
		</div>
		
	);
}

export default BetaModal;
