import React from 'react';
import Button from '../Components/Button';
import { useLocation } from 'react-router';
import './Home.css';

const HomeScreen = () => {
  return (
	<div>
		<HeroSection />
		<BuyStepsSection />
	</div>
  );
};

const HeroSection = () => {
	const location = useLocation();
	return (
		<header className="hero">
			<div className="hero-content" >
				<h1 className="title">
					Turning open-source projects into businesses
				</h1>
				<p>
					At CodeBarter, we believe that open-source developers should be able to make a living from their work. We provide a platform for developers to sell services, support, documentation, customizations and much more.	
				</p>
				<h2>Don't waste your time</h2>
				<p>
					Turning your open-source project into a business is hard. You have to deal with payments, advertising, customer support, and much more. We take care of all the boring stuff so you can focus on what you do best: writing code.
				</p>
				<div className="btn-container">
					<Button label="Get Started" to="/beta" state={{ previousLocation: location }} className="btn-primary"/>
				</div>
			</div>
		</header>
	);
}

const BuyStepsSection = () => {
	const location = useLocation();
	return (
		<div className="steps-section">
			<div className="steps-container">
				<div className="outer">
					<div className="card">
						<div className="card-info">
							<h2 className="card-title"><span className="step">1.</span>Create a product</h2>
							<p>Create several products for your project. You can sell anything from support to customizations. You can also offer free products to attract customers.</p>
						</div>
					</div>
					<div className="card">
						<div className="card-info">
							<h2 className="card-title"><span className="step">2.</span>Advertise</h2>
							<p>
								Advertise your services and products on your project repository's readme. You can also share your storefront on social media and other platforms.
							</p>
						</div>
					</div>
					<div className="card">
						<div className="card-info">
							<h2 className="card-title"><span className="step">3.</span>Enjoy</h2>
							<p>Enjoy the money you make from your project. You can withdraw your earnings at any time.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="btn-container">
				<Button label="Get Started" to="/beta" state={{ previousLocation: location }} className="btn-primary"/>
			</div>
		</div>
	);
}


export default HomeScreen;
