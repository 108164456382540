import React, { useState } from "react";
import "./Header.css";
import Button from "../Components/Button";
import { GoSearch } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";


const Header = () => {
	const location = useLocation();
	const [search, setSearch] = useState("");
	const handleSearch = () => {
		// link/go to /beta route //
		setSearch("");
	}
	return (
		<div className="header">
			<div className="gradientbar" /> 
			<div className="searchbar-container">
				<div className="logo">
					<Link to="/" className="logo-title">CodeBarter_</Link>
					<span className="slogan">The birth of FOSS businesses</span>
				</div>
				<div className="searchbar">
					<GoSearch size="25"/>
					<input type="text" placeholder="Search code" value={search} onChange={(e) => setSearch(e.target.value)} onSubmit={handleSearch}/>
					<Button label="Search" to="/beta" state={{ previousLocation: location }} className="btn-primary btn-big"/>
				</div>
				<div className="right-section">
					{
						window.location.pathname === "/publish" ?
							(<Button label="Login" to="/beta" state={{ previousLocation: location }} className="btn-big"/>) :
							(<Button label="Login" to="/beta" state={{ previousLocation: location }} className="btn-big"/>)
					}
				</div>
			</div>
		</div>
	);
};

export default Header;
