import React from 'react';
import Button from '../Components/Button';
import { useLocation } from 'react-router';
import './Publish.css';

const PublishScreen = () => {
  return (
	<>
		<HeroSection />
		<SellStepsSection />
	</>
  );
};

const HeroSection = () => {
	return (
		<header className="hero-header">
			<div className="hero-content" >
				<h1 className="title">
					Turn your code into <span className="highlight">profit</span>
				</h1>
				<p>
					It has never been easier to monetize your code. CodeBarter handles all the hard work for you. Just link your Git repository and start earning money.
				</p>
			</div>
		</header>
	);
}

const SellStepsSection = () => {
	const location = useLocation();
	return (
		<div className="steps-section">
			<div className="steps-container sell">
				<div className="outer">
					<div className="card">
						<div className="card-info">
							<h2 className="card-title"><span className="step">1.</span>Create an account</h2>
							<p>Your account will allow you to manage all your projects, communicate with your clients and see all your invoices. Your public profile will reflect your reputation and reliability as a vendor.</p>
						</div>
					</div>
					<div className="card">
						<div className="card-info">
							<h2 className="card-title"><span className="step">2.</span>Link your repo</h2>
							<p>Link your private repository to give CodeBarter access to the code. Don't worry no one we won't share it with anyone unless bought. You'll be able to customize the code's public page to show off its capabilities.</p>
						</div>
					</div>
					<div className="card">
						<div className="card-info">
							<h2 className="card-title"><span className="step">3.</span>Profit</h2>
							<p>Once you've setup everything companies and developers will be able to buy your code. No action needed on your end, they get access to the code automatically.<br />Relax & profit.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="btn-container">
				<Button label="Register" to="/beta" state={{ previousLocation: location }} className="btn-primary btn-big"/>
				<Button label="Login" to="/beta" state={{ previousLocation: location }} className="btn-big"/>
			</div>
		</div>
	);
}

export default PublishScreen;
