import React from "react";
import "./Footer.css";


const Footer = () => {
	return (
		<footer>
			<div className="footer-container">
				<p>
					© 2023 CodeBarter. All rights reserved.
				</p>
			</div>
			<div className="gradientbar" />
		</footer>
	);
};

export default Footer;
