import React from 'react';
import './Modal.css';
import { useNavigate } from 'react-router';

const Modal = ({ children }) => {
	const navigate = useNavigate();

  	return (
		<div 
			className="modal-wrapper"
			onClick={() => navigate(-1)}
		>
			<div className="modal" onClick={e => e.stopPropagation()}>
				<div className="modal-close" onClick={() => navigate(-1)}>
					&times;
				</div>
				<div className="modal-content">
					{children}
				</div>
			</div>
		</div>
  	);
};

export default Modal;
