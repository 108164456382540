import './App.css';
import React  from 'react';
import {
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
// Screens
import Layout from './Layout/Layout';
import HomeScreen from './Home/HomeScreen';
import PublishScreen from './Publish/Publish';
import BetaModal from './Modal/BetaModal';

function App() {
	const location = useLocation();
	const previousLocation = location.state?.previousLocation;

	return (
		<div className="App">
			<Routes location={previousLocation || location}>
      			<Route path="/" element={<Layout />}>
					<Route path="/" element={<HomeScreen />} />
					<Route path="/publish" element={<PublishScreen />} />
				</Route>
			</Routes>
			{previousLocation && (
				<Routes>
					<Route path="/beta" element={<BetaModal />} />
				</Routes>
			)}
		</div>
	);
}

export default App;
